import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, catchError, expand, reduce } from 'rxjs';
import { SHOW_MODAL_ON_ERROR } from 'src/app/error/interceptor.token';
import { StudisuError } from 'src/app/error/studisu-error';
import { StudisuConfig, getStudisuConfig } from '../../config/studisu.config';
import { BerufepoolNews } from './model/berufepool-news';
import { News } from './model/news';

/**
 * Service, um News der STUDISU vom Berufepool-REST-Basisdienst abzurufen.
 *
 * Vorlage: SPRAF
 */
@Injectable({
  providedIn: 'root'
})
export class BerufepoolService {
  private verfahren = 'studisu';
  private appConfig: StudisuConfig = getStudisuConfig();

  constructor(private http: HttpClient) {}

  /**
   * Liefert eine News-Ergebnis-Seite anhand der Seitennummer
   *
   * @param page Seitennummer
   */
  private getBerufepoolNews(page: number): Observable<BerufepoolNews> {
    return this.http
      .get<BerufepoolNews>(`${this.appConfig.berufepoolHost}/pc/v1/aktuellenews`, {
        responseType: 'json',
        params: {
          'verfahren.name': this.verfahren,
          page: page.toString()
        },
        context: new HttpContext().set(SHOW_MODAL_ON_ERROR, true)
      })
      .pipe(
        catchError((error) => {
          throw new StudisuError('Fehler beim laden der aktuellen News.', error);
        })
      );
  }

  /**
   * Liefert eine Archivierte-News-Ergebnis-Seite anhand der Seitennummer
   *
   * @param page Seitennummer
   */
  private getAbgelaufeneBerufepoolNews(page: number): Observable<BerufepoolNews> {
    return this.http
      .get<BerufepoolNews>(`${this.appConfig.berufepoolHost}/pc/v1/abgelaufenenews`, {
        responseType: 'json',
        params: {
          'verfahren.name': this.verfahren,
          page: page.toString()
        },
        context: new HttpContext().set(SHOW_MODAL_ON_ERROR, true)
      })
      .pipe(
        catchError((error) => {
          throw new StudisuError('Fehler beim laden der abgelaufenen News.', error);
        })
      );
  }

  /**
   * Verwendet die Pagination, um alle aktuellen News mit mehreren Requests vom Berufepool zu laden.
   */
  public getAllBerufepoolNews(): Observable<News[]> {
    return this.getBerufepoolNews(1).pipe(
      expand((berufepoolNews) =>
        berufepoolNews.page.number < berufepoolNews.page.totalPages
          ? this.getBerufepoolNews(berufepoolNews.page.number + 1)
          : EMPTY
      ),
      reduce<BerufepoolNews, News[]>(
        (news, berufepoolNews) => [...news, ...berufepoolNews._embedded.news],
        []
      )
    );
  }

  /**
   * Verwendet die Pagination, um alle abgelaufenen News mit mehreren Requests vom Berufepool zu laden.
   */
  public getAllAbgelaufeneBerufepoolNews(): Observable<News[]> {
    return this.getAbgelaufeneBerufepoolNews(1).pipe(
      expand((berufepoolNews) =>
        berufepoolNews.page.number < berufepoolNews.page.totalPages
          ? this.getAbgelaufeneBerufepoolNews(berufepoolNews.page.number + 1)
          : EMPTY
      ),
      reduce<BerufepoolNews, News[]>(
        (news, berufepoolNews) => [...news, ...berufepoolNews._embedded.news],
        []
      )
    );
  }
}
