import { Link } from './link';

/**
 * Einzelne News aus dem Berufepool
 *
 * Vorlage: SPRAF
 */
export class News {
  id: number;
  nummer: string;
  created: string;
  validFrom: string;
  validUntil: string;
  titel: string;
  text: string;
  sourceName: string;
  sourceUrl: string;
  user: string;
  onTop: boolean;
  _links: {
    self: Link;
  };

  /**
   * Sortiert die News absteigend nach Datum und sekundär nach ID.
   * News mit "onTop" werden auf die ersten Stellen sortiert falls sie
   * nicht archviert sind.
   *
   * @param news News-Array
   * @param onTopBerueckischtigen Soll das OnTopFlag bei der Sortierung berücksichtigt werden
   */
  public static sortiereNews(news: News[], onTopBerueckischtigen: boolean): News[] {
    return news.sort((a: News, b: News) => {
      if (onTopBerueckischtigen) {
        if (a.onTop && !b.onTop) {
          return -1;
        }
        if (b.onTop && !a.onTop) {
          return 1;
        }
      }
      const diff = new Date(b.validFrom).getTime() - new Date(a.validFrom).getTime();
      if (diff !== 0) {
        return diff;
      }
      // fuer deterministische reihenfolge
      return b.id - a.id;
    });
  }

  public static formatierungText(news: News) {
    let newText = news.text?.trim();
    if (!newText) {
      newText = '';
    }
    // der bw verlag liefert die news mit einem umgebenden p-tag.
    // wir entfernen dies um:
    // - keinen extra margin durch das p-tag zu bekommen
    // - im style zu __news-text_ 'line-clamp' verwenden zu können, um den text zu kürzen
    if (newText.startsWith('<p>')) {
      newText = newText.slice(3);
    }
    if (newText.endsWith('</p>')) {
      newText = newText.slice(0, newText.length - 4);
    }
    return newText;
  }

  public static formatierungDate(news: News) {
    const validFrom = news?.validFrom;
    let date = !!validFrom ? new Date(validFrom) : null;
    if (date !== null && isNaN(date.getTime())) {
      date = null;
    }
    return date;
  }
}
